<template>
  <sdUserCard>
    <div class="card user-card">
      <sdCards headless>
        <figure>
          <img
            src="@/img/profile.png"
            alt=""
          >
        </figure>
        <figcaption>
          <div class="card__content">
            <sdHeading
              class="card__name"
              as="h6"
            >
              <a to="#">{{ user.title }}</a>
            </sdHeading>
            <p class="card__designation">
              CARGONG
            </p>
          </div>
          <div class="card__info">
            <a-row :gutter="15">
              <a-col :xs="12">
                <div class="info-single">
                  <sdHeading
                    class="info-single__title"
                    as="h2"
                  >
                    {{ payment.totalPayment }} ₺
                  </sdHeading>
                  <p>{{ $t.total }}</p>
                </div>
              </a-col>
              <a-col :xs="12">
                <div class="info-single">
                  <sdHeading
                    class="info-single__title"
                    as="h2"
                  >
                    {{ payment.totalConsume }}
                  </sdHeading>
                  <p>{{ $t.records }}</p>
                </div>
              </a-col>
            </a-row>
          </div>
        </figcaption>
      </sdCards>
    </div>
  </sdUserCard>
</template>
<script>
import PropTypes from 'vue-types'

const UserCards = {
  name: 'UserCards',
  props: {
    user: PropTypes.object,
    payment: PropTypes.object,
  },
}

export default UserCards
</script>
